<template>
    <div class="uplaod__box" v-loading="loading">

        <div v-if="value" class="file">
            <div class="file_left">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none"
                    class="file_left_icon">
                    <path
                        d="M15.6097 27.4019C14.9468 28.3483 14.446 28.8216 14.1742 29.0277C14.4937 28.4514 15.1185 27.8423 15.6097 27.4019Z"
                        fill="#FF4850" />
                    <path
                        d="M19.8113 18.6825C20.0021 18.0453 20.0355 16.7849 19.7875 16.3679C19.7541 16.307 19.7207 16.2789 19.6826 16.2742C19.5443 16.4288 19.5776 17.7501 19.8113 18.6825Z"
                        fill="#FF4850" />
                    <path
                        d="M21.5759 24.1784C20.8653 23.6255 20.2215 22.8805 19.7112 22.0138C19.3439 22.9508 18.8146 24.2299 18.1803 25.3591C18.293 25.3148 18.4075 25.2688 18.5236 25.2222C18.6039 25.19 18.685 25.1574 18.7669 25.1248L18.7925 25.1145C19.6254 24.7798 20.5562 24.4056 21.5759 24.1784Z"
                        fill="#FF4850" />
                    <path
                        d="M27.1129 25.1248C27.1415 25.1061 27.1367 25.092 27.1319 25.0827L27.131 25.0812C27.1133 25.053 26.8603 24.6495 24.9811 24.7359C26.6646 25.3872 27.0556 25.1623 27.0938 25.1389L27.1033 25.1248H27.1129Z"
                        fill="#FF4850" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M34.4155 12.0241L25.6118 3.37768C25.3664 3.13661 25.035 3 24.6873 3H7.30909C6.585 3 6 3.57455 6 4.28571V37.7143C6 38.4254 6.585 39 7.30909 39H33.4909C34.215 39 34.8 38.4254 34.8 37.7143V12.9362C34.8 12.5946 34.6609 12.2652 34.4155 12.0241ZM24.3698 13.5268C24.2108 13.5268 24.0818 13.4001 24.0818 13.2439V6.64806C24.0818 6.39606 24.392 6.26986 24.5735 6.44805L31.2893 13.0439C31.4707 13.2221 31.3422 13.5268 31.0856 13.5268H24.3698ZM20.8558 20.2193L20.8415 20.2849C21.3994 21.7092 22.1673 22.665 23.3405 23.3678C25.7251 23.0773 27.2559 23.2366 27.8855 23.8457C28.3195 24.2721 28.4482 25.0686 28.1812 25.7011C28.0285 26.0619 27.6232 26.6616 26.593 26.6616C25.5534 26.6616 24.0225 26.2071 22.6776 25.4997C21.4783 25.6983 20.1538 26.1385 18.8734 26.564L18.8193 26.582C18.5427 26.6757 18.2566 26.7694 17.98 26.8584C16.1677 29.9133 14.8085 30.5786 13.9691 30.5786C13.7688 30.5786 13.5971 30.5364 13.4541 30.4849C13.125 30.363 12.8436 30.11 12.6672 29.7821C12.5098 29.4869 12.443 29.1589 12.5002 28.9012C12.7816 27.505 14.675 26.3992 16.0866 25.6824C16.8449 24.3705 17.8798 22.1637 18.5284 20.6456C18.0134 18.6872 17.732 17.1504 18.0467 16.0259C18.2137 15.4121 18.8861 14.9904 19.6396 15.0232C20.2787 15.0513 21.0275 15.4402 21.2087 16.4382C21.479 17.6963 21.1362 19.0841 20.8603 20.2008L20.8558 20.2193Z"
                        fill="#FF4850" />
                </svg>
                <div class="file_left_name">{{ $utils.nameRender(value) }}</div>
            </div>
            <div class="file_right">
                <i class="el-icon-ali-yulan" @click="$utils.ossOpen(value)"></i>
                <i class="el-icon-ali-shanchuwenjian" style="margin-left: 10px;" @click="handleRemove"></i>
            </div>

        </div>
        <el-upload v-else :show-file-list="false" accept=".pdf,.docx,.doc,.wps" :on-change="onChange" :limit="1"
            action="" :auto-upload="false" :on-exceed="handleExceed" :before-remove="handleRemove" multiple
            :before-upload="beforeUpload">
            <div class="upload">
                <i class="el-icon-ali-xingzhuangjiehe"></i>
                {{ label }}上传
            </div>
        </el-upload>
    </div>
</template>

<script>
import uploadFile from '@/utils/oss.js'  // 引入oss.js文件
export default {
    props: {
        value: {
            type: String,
            default: ''
        },
        limit: {
            type: Number,
            default: 1
        },
        label: {
            type: String,
            default: '仲裁裁决书'
        }
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        nameRender(name) {
            if (name.indexOf('/')) {
                return name.split('/')[1].slice(32)
            } else {
                return ''
            }
        },
        handleRemove() {
            this.$emit('input', '')
        },
        beforeUpload() {
            this.loading = true;
            return true
        },
        async onChange(file) {
            const { data } = await this.$api.getFileSts();
            const response = await uploadFile(file.raw, data);
            if (response.res.status === 200) {
                this.$emit('input', response.name)
            } else {
                this.$message({
                    type: 'error',
                    message: '上传失败，请联系管理员'
                })
                console.error(response)
            }
            this.loading = false
        },
        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 ${this.limit} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        }
    }
}
</script>

<style lang="scss" scoped>
.uplaod__box {
    .upload {
        border-radius: 4px;
        border: 1px dashed #66A1FF;
        background: #F5F9FF;
        width: 400px;
        height: 83px;
        flex-shrink: 0;
        line-height: 83px;

        color: #0062FF;
        font-family: "Source Han Sans CN";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
    }

    .file {
        width: 400px;
        height: 83px;
        flex-shrink: 0;
        border-radius: 4px;
        border: 1px solid #eee;
        background: #F7F8F9;
        display: flex;
        align-items: center;
        padding: 0 20px;
        justify-content: space-between;

        &_left {
            display: flex;
            align-items: center;

            &_name {
                margin-left: 10px;
            }

        }

        &_right {
            i {
                font-size: 12px;
                color: #999;
            }

        }
    }
}
</style>