<template>
    <div class="box">
        <el-form :model="form" ref="form" :rules="rules" label-position="top">
            <div class="top">
                <div class="top-l" @click="$router.replace({ path: '/equity/execute' })">
                    <i class="el-icon-close"></i>
                    <p>关闭</p>
                </div>
                <div class="top-c">
                    创建权益执行
                </div>
                <div class="top-r" @click="submitForm">提交</div>
            </div>
            <div class="content">
                <div class="form">
                    <div class="title">
                        <i class="solid"></i>
                        <p>主体信息</p>
                    </div>
                    <div class="dec">
                        申请方
                    </div>
                    <el-row :gutter="20">
                        <el-col :span="8">
                            <el-form-item prop="subjectTypeA" label="申请方类型">
                                <el-select v-model="form.subjectTypeA" placeholder="请选择申请方类型" style="width: 100%;"
                                    clearable>
                                    <el-option v-for="item in subjectType" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>

                        <el-col :span="8">
                            <el-form-item prop="type" label="类型">
                                <el-select v-model="form.type" placeholder="请选择类型" style="width: 100%;">
                                    <el-option v-for="item in enforceType" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item prop="subjectA" label="申请方">
                                <el-input v-model="form.subjectA" placeholder="请输入申请方名称"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" v-show="form.subjectTypeA == 1">
                            <el-form-item prop="contactA" label="申请方联系人">
                                <el-input v-model="form.contactA" placeholder="请输入申请方联系人 "></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item prop="phoneA" label="申请方电话">
                                <el-input v-model="form.phoneA" placeholder="请输入申请方电话 "></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item prop="weixinA" label="申请方微信">
                                <el-input v-model="form.weixinA" placeholder="请输入申请方微信 "></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <div class="dec">
                        被申请方
                    </div>
                    <el-row :gutter="20">
                        <el-col :span="8">
                            <el-form-item prop="subjectTypeB" label="被申请方类型">
                                <el-select v-model="form.subjectTypeB" placeholder="请选择被申请方类型" style="width: 100%;">
                                    <el-option v-for="item in subjectType" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item prop="subjectB" label="被申请方名称">
                                <el-input v-model="form.subjectB" placeholder="请输入被申请方名称"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item prop="phoneB" label="被申请方电话">
                                <el-input v-model="form.phoneB" placeholder="请输入被申请方电话"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item prop="weixinB" label="被申请方微信">
                                <el-input v-model="form.weixinB" placeholder="请输入被申请方微信 "></el-input>
                            </el-form-item>
                        </el-col>

                    </el-row>
                </div>

                <div class="bottom">
                    <div class="title">
                        <i class="solid"></i>
                        <p>裁判文书</p>
                    </div>
                    <el-row :gutter="20">
                        <el-col :span="8">
                            <el-form-item prop="verdictUrl" label="裁决文书">
                                <caijuewenshushangchuan v-model="form.verdictUrl" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item prop="proofOfService" label="送达回证">
                                <caijuewenshushangchuan v-model="form.proofOfService" label="送达回证" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item prop="proofOfEffective" label="生效证明">
                                <caijuewenshushangchuan v-model="form.proofOfEffective" label="生效证明" />
                            </el-form-item>
                        </el-col>

                    </el-row>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
import caijuewenshushangchuan from "@/components/caijuewenshushangchuan.vue"
import selectData from '@/mixins/selectData.js'
export default {
    mixins: [selectData],
    components: {
        caijuewenshushangchuan
    },
    data() {
        return {
            form: {
                type: null,
                subjectTypeA: null,
                subjectA: '',
                contactA: '',
                phoneA: '',
                weixinA: '',
                subjectTypeB: null,
                subjectB: null,
                phoneB: '',
                weixinB: '',
                verdictUrl: '',
                proofOfService: '',
                proofOfEffective: '',

            },
            rules: {
                type: [{ required: true, message: '请选择类型', trigger: 'blur' }],
                subjectTypeA: [{ required: true, message: '请选择申请方类型', trigger: 'blur' }],
                subjectA: [{ required: true, message: '请输入申请方名称', trigger: 'blur' }],
                phoneA: [{ required: true, message: '请输入申请方电话', trigger: 'blur' }],
                subjectTypeB: [{ required: true, message: '请选择被申请方类型', trigger: 'blur' }],
                subjectB: [{ required: true, message: '请输入被申请方名称', trigger: 'blur' }],
                verdictUrl: [{ required: true, message: '请上传裁决文书', trigger: 'blur' }],
            }
        }
    },
    methods: {
        submitForm() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.postEnforceAdd()
                } else {
                    return false;
                }
            })
        },
        async postEnforceAdd() {
            const { status, info } = await this.$api.postEnforceAdd(this.form)
            if (status === 200) {
                this.$message({
                    type: 'success',
                    message: info
                })
                this.$router.go(-1)
            } else {
                this.$message({
                    type: 'error',
                    message: info
                })
            }
        }
    }


}
</script>

<style lang="scss" scoped>
.box {
    .top {
        display: flex;
        height: 64px;
        justify-content: space-between;
        padding: 0 24px;
        align-items: center;

        &-l {
            display: flex;
            color: #999;
            text-align: center;
            font-family: "Source Han Sans CN";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            align-items: center;
            cursor: pointer;

            p {
                margin-left: 4px;
            }
        }

        &-c {
            color: #262626;

            font-family: "Source Han Sans CN";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        &-r {
            width: 78px;
            height: 34.277px;
            flex-shrink: 0;
            border-radius: 2px;
            background: #0062FF;
            color: #FFF;
            text-align: center;
            font-family: "Source Han Sans CN";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 34.277px;
            cursor: pointer;
        }
    }

    .content {
        width: 1280px;
        margin: 0 auto;
        margin-top: 16px;

        .title {
            height: 60px;
            display: flex;
            font-family: "Source Han Sans CN";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            align-items: center;

            .solid {
                border-radius: 10px;
                background: #0062FF;
                width: 2px;
                height: 16px;
                margin-right: 10px;
            }
        }

        .form,
        .bottom {
            padding: 0 20px;
            background: #fff;
            border-radius: 6px;

            .dec {
                height: 40px;
                flex-shrink: 0;
                background: #F7F8F9;
                line-height: 40px;
                padding: 0 20px;
                color: #666;
                font-family: "Source Han Sans CN";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
            }
        }

        .bottom {
            margin-top: 16px;


        }
    }

}
</style>