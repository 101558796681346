// import { ElMessage } from "element-plus";
import OSS from 'ali-oss'

//  上传
const uploadFile = (file, option) => {
	const client = new OSS({
		// yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
		region: 'oss-cn-beijing',
		// 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
		accessKeyId: option.accessKeyId,
		accessKeySecret: option.accessKeySecret,
		// 从STS服务获取的安全令牌（SecurityToken）。
		stsToken: option.securityToken,
		// 填写Bucket名称。
		bucket: 'meimengapp'
	});

	function guid (len = 32, firstU = true, radix = null) {
		const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
		const uuid = []
		radix = radix || chars.length

		if (len) {
			// 如果指定uuid长度,只是取随机的字符,0|x为位运算,能去掉x的小数位,返回整数位
			for (let i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix]
		} else {
			let r
			// rfc4122标准要求返回的uuid中,某些位为固定的字符
			uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
			uuid[14] = '4'

			for (let i = 0; i < 36; i++) {
				if (!uuid[i]) {
					r = 0 | Math.random() * 16
					uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r]
				}
			}
		}
		// 移除第一个字符,并用u替代,因为第一个字符为数值时,该guuid不能用作id或者class
		if (firstU) {
			uuid.shift()
			return `u${uuid.join('')}`
		}
		return uuid.join('')
	}

	return client.put(
		`znsign/${guid() + file.name}`,
		file
		//{headers}
	);

};
export default uploadFile